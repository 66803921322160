<template>
    <div>
      <h1>Leni Yang's CV</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CVView',
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>