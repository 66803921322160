
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCaretLeft,faCaretRight,faTimes,faAnglesDown,faFilePdf,faLink,faStar,faGraduationCap,faPersonWalkingLuggage,faPersonChalkboard, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {faSpaceAwesome} from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(faCaretLeft,faCaretRight,faTimes,faAnglesDown,faFilePdf,faLink,faStar,faGraduationCap,faSpaceAwesome,faPersonWalkingLuggage,faPersonChalkboard, faEnvelope)

import 'bootstrap/dist/css/bootstrap.css'

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(router)
.mount('#app')